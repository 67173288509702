@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300;400;500;700&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
}

h1, h2 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 500;
}
p {
  white-space: pre-line;
}
a {
  color:#26418f;
}
.ua {
  background: linear-gradient(to bottom, #1b9ee5 0%, #1b9ee5 49.99%, #f4dc00 50%, #f4dc00 100%);
}
.logo {
  max-height: 60px;
  margin-top: -20px;
}
.header-logo {
  width: 100vw;
  background: url('https://adoptpets.eu/static/assets/img/banner.jpg') no-repeat top center;
  height: 650px;
  background-size: cover;
}
.overlay {
  background: linear-gradient(to top,#26418f, #5c6bc0);
  height: 650px;
  width: 100vw;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
  opacity: .6;
}
.overlay h1 {
  font-family: 'Varela Round', sans-serif;
}
.bg-main {
  background-color: #5c6bc0;
}
.bg-blue {
  background-color: #26418f;
}
.btn-primary {
  background-color: #ff8f00;
  border: 1px #ff8f00;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-primary.disabled, .btn-primary:disabled {
  background-color: #E86F4A;
  border: 1px #E86F4A;
}
.title {
  font-family: 'Varela Round', sans-serif;
  color: #5c6bc0;
  font-size: 3em;
}
a.logo-block:hover{
  text-decoration: none;
}
.img-preview {
  width: 200px;
  height: 200px;
}
.card-title {
  text-align: center;
  color: #5c6bc0;
}
a:hover {
  text-decoration: none;
}
a .card:hover {
  -webkit-box-shadow: 0 0 .46667rem 0 rgba(0,0,0,.2);
  box-shadow: 0 0 .46667rem 0 rgba(0,0,0,.2);
}
.login-block {
  padding-left: 1em;
  border-left: 1px solid #5c6bc0;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 500;
  font-size: 1.25em;
}
.text-main a {
  color: #5c6bc0;
}
.navigation {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 500;
  font-size: 1.25em;
  width: 100%;
}
#basic-navbar-nav {
  justify-content: space-between;
}
.navigation a {
  color: #fff;
}
.navigation i {
  margin-right: .5em;
}
.icons {
  max-height: 50px;
  margin-right: .5em;
}
#content {
  padding-bottom: 200px;
}
.footer {
  height: 180px;
  position: absolute;
  bottom: 0;
}
.footer-links a {
  text-transform: uppercase;
  font-family: "Fira Sans Condensed",sans-serif;
  font-weight: 500;
}
.copyrights {
  font-size: .9em;
}
.like-button {
  position: absolute;
  top: 0.5em;
  right: 1.5em;
  background-color: #fff;
  padding: 0.4em 0.3em;
  width: 32px;
  height: 32px;
  border: 1px solid #5c6bc0;
  border-radius: 16px;
  opacity: .7;
  cursor: pointer;
  z-index: 99;
}
.like-button i {
  color: #5c6bc0;
  font-size: 1.25em;
}
.like-button:hover {
  opacity: 1;
}
.liked i {
  color: #5c6bc0
}
li.slide {
  display: flex;
  background-color: #26418f;
}
.thumbs-wrapper {
  margin: 1em 0!important;
}
ul.thumbs {
  overflow-y: scroll;
  padding-inline-start: 0;
}
.thumb.selected {
  border: 1px solid #26418f!important;
}
.thumb:hover {
  border: 1px solid #26418f!important;
}
.remove-image {
  position: absolute;
  top: -.5em;
  right: 0;
  color: #5c6bc0;
  font-size: 2em;
  opacity: .7;
  cursor: pointer;
  z-index: 99;
}
.remove-image:hover {
  opacity: 1;
}
.save-block {
  width: 150px;
  height: 50px;
  background-color: rgba(255,229,100,.3);
  text-align: center;
  margin-left: 1em;
  font-size: 1.5em;
  line-height: 2;
  display: none;
}
.sort-form label {
  color: #fff;
}
button.btn, div.btn, a.btn {
  border-radius: 2em;
}
.request-form, .about-org {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}
.request-form {
  color: #fff;
}
.btn-outline {
  border: 2px solid #fff;
  color: #fff;
  font-size: 1.25em;
  cursor: pointer;
}
.btn-white {
  background-color: #fff;
  border: 2px solid #fff;
  color: #26418f;
  font-size: 1.25em;
  cursor: pointer;
}
.btn-outline:hover, .btn-white:hover, .btn-blue-outline:hover {
  background-color: #ff8f00;
  border: 2px solid #ff8f00;
  color: #fff;
}
.btn-blue-outline {
  border: 2px solid #26418f;
  color: #26418f;
  font-size: 1.25em;
}
div.btn i, a.btn i {
  margin-right: .5em;
}
h3.page-title {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 500;
}
.stripe-form {
  background-color: #f3f3f3;
}
.stripe-form button{
  border-radius: 0;
  width: 100%;
  height: 38px;
}
.stripe-form .input-group-text, .stripe-form .form-control {
  border-radius: 0;
}
#payment, #subscription, .donate {
  background-color: #fff;
  cursor: pointer;
  line-height: 38px;
  text-align: center;
  height: 38px;
}
.stripe-form .active {
  background-color: #ff8f00!important;
  color: #fff;
}
.org-logo {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(-60px);
  width: 120px;
  height: 120px;
  display: flex;
  border: 2px solid #26418f;
  background-color: #fff;
  border-radius: 60px;
} 
.org-logo img{
  max-width: 100px;
  max-height: 100px;
  margin: auto;
}
.about-org {
  margin-top: 6em!important;
}
.s-buttons {
  display: flex;
  flex-direction: column;
}
h2.index-title {
  text-align: center;
  color: #26418f;
  font-size: 2.25em;
  font-family: 'Fira Sans Condensed';
}
p.index-block {
  text-align: center;
  font-size: 1.25em;
  line-height: 1.5;
  margin-bottom: auto;
}
p.index-block__tagline {
  text-align: center;
  font-size: 1.5em;
  color: #5c6bc0;
}
p.hint {
  text-align: center;
  color: #26418f;
}
.step {
  position: absolute;
  right: 1em;
  top: -.5em;
  font-size: 1.5em;
  width: 1.75em;
  height: 1.75em;
  text-align: center;
  line-height: 1.75;
  border-radius: 50%;
  border: 1px solid #26418f;
  background-color: #5c6bc0;
  color: #fff;
}
h5.how-title {
  color: #5c6bc0;
}
p.how-text {
  margin-right: 1em;
}
p.card-text{
  text-align: center;
  color: #26418f;
  margin-bottom: 0;
}
p.card-text:hover {
  color: #26418f;
}
.message {
  text-align: center;
}
.text-small {
  font-size: 0.9em;
}
.navbar {
  padding: .5rem 0!important;
}
.thanks-image {
  max-width: 500px;
}
.modal-block .modal-dialog {
  max-width: 300px;
}
.attention {
  text-align: center;
}
.attention .row {
  border-top: 1px #26418f solid;
}
@media screen and (max-width: 767px) {
  .login-block {
    position: absolute;
    right: .5rem;
  }
  .text-main {
    line-height: 56px;
  }
  .text-main a {
    color: #fff;
  }
}
@media screen and (max-width: 425px) {
  .logo {
    margin-top: .5em;
  }
  .title {
    font-size: 2em;
  }
}
@media screen and (max-width: 1199px) {
  .header-logo, .overlay {
    height: 250px;
  }
}
@media screen and (max-width: 374px) {
  .footer-links {
    display: flex;
    flex-direction: column;
  }
}